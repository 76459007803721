const config = {
    baseUrl: 'https://api.activate.smallhd.com/apis/',
    //baseUrl: 'http://localhost:3200/apis/',
    init: function () {
        window.localStorage.removeItem('_purchaser_email');
        window.localStorage.removeItem('_verified_email');
        window.localStorage.removeItem('_activation');
        window.localStorage.removeItem('_license_name');
        window.localStorage.removeItem('_license_key');
    },
    setPurchaserEmail: function (email) {
        window.localStorage.setItem('_purchaser_email', email);
    },
    setVerifiedEmail: function (email) {
        window.localStorage.setItem('_verified_email', email);
    },
    setLicenseName: function (licenseName) {
        window.localStorage.setItem('_license_name', licenseName);
    },
    setLicenseKey: function (licenseKey) {
        window.localStorage.setItem('_license_key', licenseKey);
    },
    setLicenseItemName: function (licenseItemName) {
        window.localStorage.setItem('_license_item_name', licenseItemName);
    }, 
    getPurchaserEmail: function () {
        return window.localStorage.getItem('_purchaser_email');
    },
    getVerifiedEmail: function () {
        return window.localStorage.getItem('_verified_email');
    },
    getLicenseName: function () {
        return window.localStorage.getItem('_license_name');
    },
    getLicenseItemName: function () {
        return window.localStorage.getItem('_license_item_name');
    },
    getLicenseKey: function () {
        return window.localStorage.getItem('_license_key');
    },
    setActivation: function (obj) {
        window.localStorage.setItem('_activation', JSON.stringify(obj));
    },
    getActivation: function () {
        try {
            return JSON.parse(window.localStorage.getItem('_activation')) || {};
        } catch (e) {

        }

        return {};
    },
};

export default config;
