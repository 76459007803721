import React, { Component } from 'react';
//import logo from './logo.svg';
//import './App.css';
import './css/bootstrap.min.css';
import './css/bs5-global-custom.css';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Home from './components/home';
import KeyReady from './components/activation/key-ready';
import KeyConsumed from './components/activation/key-consumed';
import KeySent from './components/activation/key-sent';
import KeyResent from './components/activation/key-resent';
import config from './config';
import Activation from './components/activation';
// import Terms from './components/terms';

class App extends Component {

  constructor(props) {
    super(props);
    config.init();
  }

  render() {
    return (
      <BrowserRouter>

        <Switch>
          <Route path='/' exact component={Home} />          

          <Route path='/activate' component={Activation} />

          <Route path='/Key-consumed' component={KeyConsumed} />

          <Route path='/key-ready' component={KeyReady} />

          <Route path='/sent' component={KeySent} />
          <Route path='/resent' component={KeyResent} />
          
        </Switch>

      </BrowserRouter>
    );
  }
}

export default App;
