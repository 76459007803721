import React, { Component } from 'react';

import Header from '../common/header';

import Contact from '../common/contact';

import config from '../../config';

import queryString from 'query-string';

import { Redirect } from "react-router-dom";
import Footer from '../common/footer';


//view-source:file:///D:/projects/License%20App/html/key-consumed.html
export default class KeyConsumed extends Component {

    constructor(props) {

        super(props);

        this.state = {
            resend: false,
            redirectContact: false,
        };

        this.qs = queryString.parse(window.location.search);

        this.resendBtn = this.resend.bind(this);

        this.openModal = this.openModal.bind(this);        

    }
    
    resend() {
        this.setState({
            resend: true
        });
    }

    openModal() {
       
        const modalVisible = !this.state.modalVisible;
        if(modalVisible) {
            document.body.classList.add('modal-open');
        }
        else
        {
            document.body.classList.remove('modal-open');
        }
        this.setState({
          modalVisible
        });
      }


    render() {

        if (this.state.resend) {
            return <Redirect to={'/resent?key=' + this.qs.key} push></Redirect>
        }

        return (
            
            <div className="wrapper">
                <Header />
                
                <section className="content flex-center">
                    <div className="container-pf-mini py-md-27 py-20">
                        <div className="text-center">
                            <i className="fa fa-link fa-5x mb-4" aria-hidden="true"></i>
                            <h2 className="pt-3 fw-medium">Already Consumed!</h2>
                        </div>

                        <div className="text-center process-detail">
                            <h3 className="mb-2">Looks like a license key has already been generated and sent to <span className="d-block">{config.getPurchaserEmail()}</span> </h3>
                            <a href="javascript:;" onClick={this.resendBtn} className="btn-primary btn-block fs-md-6 fs-5 fw-bold py-4 px-6">Resend License Key</a>
                            {/* <h3 className="light">or <a href="#"><ins>Deactivate License</ins></a></h3> */}
                        </div>

                        <div className="text-center">
                            <p className="dark bold">For any assistance, <a href='javascript:;' onClick={this.openModal} className="bold dark"><ins>Contact Support</ins></a></p>
                        </div>
                    </div>
                </section>
                <Footer />
                <Contact 
                    openModal = { this.openModal }
                    modalVisible = { this.state.modalVisible }
                />                
            </div>

        );
    }

}