import React, { Component } from 'react';

import axios from 'axios';

import Header from '../common/header';

import Contact from '../common/contact';

import config from '../../config';

import queryString from 'query-string';

import { Redirect } from "react-router-dom";
import Footer from '../common/footer';

export default class KeySent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectContact: false
        };

        this.state = { modalVisible: false, };

        this.qs = queryString.parse(window.location.search);

        this.resendEmailBtn = this.resendEmail.bind(this);

        this.openModal = this.openModal.bind(this);


        document.body.classList.add('success');
    }
    componentWillUnmount() {
        document.body.classList.remove('success');
    }

    resendEmail() {

        var element = document.getElementById('resend-email-time');
        console.log(element);
        if (element) { element.parentNode.removeChild(element); }
        var timeRsp = document.createElement('small');
        timeRsp.setAttribute('id', 'resend-email-time');
        timeRsp.className = 'muted resend-email-time d-block text-white';

        timeRsp.innerHTML = 'Sending...';
        document.getElementById('licence-key-sent').append(timeRsp);

        axios.get(`${config.baseUrl}`, {
            params: {
                action: 'getgeneratelincesekey',
                activation_key: this.qs.key || '',
                additional_emails: this.state.additional_emails,
                phone: this.state.phone,
                verify_email: this.state.verifiedEmail || config.getVerifiedEmail(),
                customer_email: config.getPurchaserEmail() || config.getVerifiedEmail() || this.state.verifiedEmail
            }
        }).then((e) => {

            const response = e.data;

            if (response.status == 'success') {

                var secs = 0;
                document.getElementById('resend-email-time').innerHTML = 'Just sent';
                window.setInterval(
                    function () {
                        secs = secs + 1;
                        var txt = 'Sent ' + secs + ' minutes ago.';
                        document.getElementById('resend-email-time').innerHTML = txt;
                    }, 60000);


                return;

            }

        });
    }

    openModal() {

        const modalVisible = !this.state.modalVisible;
        if (modalVisible) {
            document.body.classList.add('modal-open');
        }
        else {
            document.body.classList.remove('modal-open');
        }
        this.setState({
            modalVisible
        });
    }

    render() {

        return (
            <div className="wrapper success">
                <Header></Header>

                <section className="content flex-center">
                    <div className="container-pf-mini py-md-27 py-20">
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <div className="text-center" id="licence-key-sent">
                                    <i className="fa fa-paper-plane-o mb-4 fa-4x" aria-hidden="true"></i>
                                    <h3>Here is your license Key!</h3>
                                    <h3 className="mt-4"><span className="license-key">{config.getLicenseKey()}</span></h3>
                                    <p>An email has also been sent to all recepients. <a href="javascript:;" onClick={this.resendEmailBtn} className="underline"><ins>Resend Email</ins></a></p>
                                </div>

                                <div className="text-center process-detail">
                                    <h2 className="mb-3">Whats Next?</h2>
                                    <p className="mb-2">Activate the license on your monitor. Here is how:</p>
                                    <div className="detail video-wrapper linear-background">
                                        <video src="images/LicenseEntry_IRL.mp4" autoPlay={true} controls={true} loop={true} className="w-100 video-style" />
                                    </div>
                                    <p className="mt-2">For any assistance. <a onClick={this.openModal} href='javascript:;'><ins>Contact Support</ins></a></p>
                                </div>

                                <div className="text-center">
                                    <a href="javascript:;" onClick={() => window.open("https://www.smallhd.com/#news", "_blank")} className="btn-hollow">Consider subscribing to our newsletter</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <Contact
                    openModal={this.openModal}
                    modalVisible={this.state.modalVisible}
                />
            </div>

        );
    }

}