import React, { Component } from 'react';

export default class Header extends Component {

    render() {

        return (
            <header className='bg-black'>
                <nav className="navbar navbar-expand-md p-0 fixed-top bg-black">
                    <div className="container-pf-mini d-md-flex align-items-center">
                        <div className='d-flex justify-content-between' style={{paddingTop: "15px", paddingBottom: "15px"}}>
                            <a href='https://www.smallhd.com' className='text-decoration-none d-flex align-items-center'><img src="https://smallhd.com/cdn/shop/files/SmallHD_Horizontal_Lockup_White_1132x180.png?v=1613760548" className='logo' alt="logo" /></a>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                        </div>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item">
                                    <a className="nav-link text-gray-3 fs-md-7 text-uppercase ls-px-md-12 px-md-6 fw-bold text-center mt-15 mt-md-0" href="https://smallhd.com/collections/all-products">Product</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-gray-3 fs-md-7 text-uppercase ls-px-md-12 px-md-6 fw-bold text-center" href="https://support.cs.inc/s/">Support</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-gray-3 fs-md-7 text-uppercase ls-px-md-12 px-md-6 fw-bold text-center mb-15 mb-md-0" href="https://smallhd.com/pages/about">About</a>
                                </li>
                            </ul>
                            <ul className='navbar-nav ms-auto d-none d-md-block'>
                                <li className="nav-item">
                                    <a href="https://www.smallhd.com" className="nav-link p-0"><i className="fa fa-user-circle-o fa-2x text-gray-3 fs-5" aria-hidden="true"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
                {/* <div className="container-fluid">
                    <div className="row">
                        <div className="col align-self-center">
                            <div className="logo">
                            <a href="https://www.smallhd.com" target="_blank"> <img src="https://activate.smallhd.com/images/logo.png" alt="logo" /> </a>
                            </div>
                        </div>

                        <div className="col align-self-center text-center">
                            <h1 className="m-0">Activation</h1>
                        </div>

                        <div className="col align-self-center text-right">
                            <div className="user">
                                <a href="https://www.smallhd.com" target="_blank"><i className="fa fa-user-circle-o fa-2x" aria-hidden="true"></i></a>
                            </div>
                        </div>
                    </div>
                </div> */}
            </header>
        );

    }

}
