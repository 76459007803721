import React, { Component } from 'react';

export default class Footer extends Component {

    render() {
        const scrollToTop = () => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        };
        return (
            <footer className='bg-black pb-md-14 pb-15'>
                <div className="container-pf-mini">
                    <div className="d-flex">
                        <div className="footer-logo-container">
                            <a href="https://smallhd.com/" className="text-decoration-none d-block">
                                <img src="https://cdn.shopify.com/s/files/1/0097/6004/7167/files/SmallHD_Lockup.png?v=1704351882" className="footer-logo img-fluid" alt="Logo Img" />
                            </a>
                        </div>

                        <div className="footer-link-lists d-none d-md-flex justify-content-evenly">
                            <div className="site-footer-block-menu has-accordion accordion--active">
                                <h2 className="site-footer-block-title mb-md-3 border-0">
                                    <p className="fw-bold ls-px-112 fs-md-7 fs-7 text-gray-3 text-uppercase mb-md-0 footer-headingsM">About Us</p>
                                </h2>
                                <div className="site-footer-block-content">
                                    <ul className="navmenu navmenu-depth-1 ps-0 list-unstyled">
                                        <li className="navmenu-item navmenu-id-company-info">
                                            <a className="navmenu-link " href="https://smallhd.com/pages/about">
                                                Company Info
                                            </a>
                                        </li>
                                        <li className="navmenu-item navmenu-id-resellers">
                                            <a className="navmenu-link " href="https://smallhd.com/pages/resellers?SmallHD=1">
                                                Resellers
                                            </a>
                                        </li>
                                        <li className="navmenu-item navmenu-id-careers">
                                            <a className="navmenu-link " href="https://cs.inc/pages/cs-careers">
                                                Careers
                                            </a>
                                        </li>
                                        <li className="navmenu-item navmenu-id-contact-us">
                                            <a className="navmenu-link " href="https://smallhd.com/pages/contact-us">
                                                Contact Us
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="site-footer-block-menu has-accordion accordion--active">
                                <h2 className="site-footer-block-title mb-md-3 border-0">
                                    <p className="fw-bold ls-px-112 fs-md-7 fs-7 text-gray-3 text-uppercase mb-md-0 footer-headingsM">Support</p>
                                </h2>
                                <div className="site-footer-block-content">
                                    <ul className="navmenu navmenu-depth-1 ps-0 list-unstyled" data-navmenu="">
                                        <li className="navmenu-item navmenu-id-faqs">
                                            <a className="navmenu-link " href="https://support.cs.inc/s/">
                                                FAQs
                                            </a>
                                        </li>
                                        <li className="navmenu-item navmenu-id-user-guides">
                                            <a className="navmenu-link " href="https://guide.smallhd.com/">
                                                User Guides
                                            </a>
                                        </li>
                                        <li className="navmenu-item navmenu-id-contact-support">
                                            <a className="navmenu-link " href="https://support.cs.inc/s/contactsupport">
                                                Contact Support
                                            </a>
                                        </li>
                                        <li className="navmenu-item navmenu-id-downloads">
                                            <a className="navmenu-link " href="https://downloads.smallhd.com/">
                                                Downloads
                                            </a>
                                        </li>
                                        <li className="navmenu-item navmenu-id-monitor-activation">
                                            <a className="navmenu-link " href="https://register.smallhd.com/">
                                                Monitor Activation
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="site-footer-block-menu has-accordion accordion--active">
                                <h2 className="site-footer-block-title mb-md-3 border-0">
                                    <p className="fw-bold ls-px-112 fs-md-7 fs-7 text-gray-3 text-uppercase mb-md-0 footer-headingsM">Legal</p>
                                </h2>
                                <div className="site-footer-block-content accordion--content" data-accordion-content="">
                                    <ul className="navmenu navmenu-depth-1 ps-0 list-unstyled" data-navmenu="">
                                        <li className="navmenu-item navmenu-id-privacy-policy">
                                            <a className="navmenu-link " href="https://smallhd.com/pages/privacy-policy">
                                                Privacy Policy
                                            </a>
                                        </li>
                                        <li className="navmenu-item navmenu-id-cookie-policy">
                                            <a className="navmenu-link " href="https://smallhd.com/pages/cookie-policy">
                                                Cookie Policy
                                            </a>
                                        </li>
                                        <li className="navmenu-item navmenu-id-return-policy">
                                            <a className="navmenu-link " href="https://smallhd.com/pages/return-policy">
                                                Return Policy
                                            </a>
                                        </li>
                                        <li className="navmenu-item navmenu-id-shipping-policy">
                                            <a className="navmenu-link " href="https://smallhd.com/pages/shipping-policy">
                                                Shipping Policy
                                            </a>
                                        </li>
                                        <li className="navmenu-item navmenu-id-sale-terms">
                                            <a className="navmenu-link " href="https://smallhd.com/pages/terms-and-conditions-of-sale">
                                                Sale Terms
                                            </a>
                                        </li>
                                        <li className="navmenu-item navmenu-id-warranty">
                                            <a className="navmenu-link " href="https://smallhd.com/pages/standard-limited-warranty-and-exclusions">
                                                Warranty
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="d-block d-md-none">
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item border-start-0 border-end-0 bg-black border-gray-6">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed fw-bold text-gray-3 text-uppercase fs-7 ls-px-md-112 ls-px-12" style={{ backgroundColor: "#000 !important", borderTop: "1px solid #333 !important;", borderBottom: "1px solid #333 !important" }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                        <span className="text-white">About Us</span>
                                    </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div className="accordion-body" style={{ background: "#000;" }}>
                                        <nav className="nav flex-column">

                                            <a className="nav-link fs-5 fw-normal footer-link px-0 mb-md-4 text-start" style={{ color: "#fff" }} href="https://smallhd.com/pages/about">Company Info</a>

                                            <a className="nav-link fs-5 fw-normal footer-link px-0 mb-md-4 text-start" style={{ color: "#fff" }} href="https://smallhd.com/pages/resellers?SmallHD=1">Resellers</a>

                                            <a className="nav-link fs-5 fw-normal footer-link px-0 mb-md-4 text-start" style={{ color: "#fff" }} href="https://cs.inc/pages/cs-careers">Careers</a>

                                            <a className="nav-link fs-5 fw-normal footer-link px-0 mb-md-4 text-start" style={{ color: "#fff" }} href="https://smallhd.com/pages/contact-us">Contact Us</a>

                                        </nav>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item border-start-0 border-end-0 bg-black border-gray-6">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed fw-bold text-gray-3 text-uppercase fs-7 ls-px-md-112 ls-px-12" style={{ backgroundColor: "#000 !important", borderTop: "1px solid #333 !important;", borderBottom: "1px solid #333 !important" }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        <span className="text-white">Support</span>
                                    </button>
                                </h2>
                                <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div className="accordion-body" style={{ background: "#000;" }}>
                                        <nav className="nav flex-column">

                                            <a className="nav-link fs-5 fw-normal footer-link px-0 mb-md-4 text-start" style={{ color: "#fff" }} href="https://support.cs.inc/s/">FAQs</a>

                                            <a className="nav-link fs-5 fw-normal footer-link px-0 mb-md-4 text-start" style={{ color: "#fff" }} href="https://guide.smallhd.com/">User Guides</a>

                                            <a className="nav-link fs-5 fw-normal footer-link px-0 mb-md-4 text-start" style={{ color: "#fff" }} href="https://support.cs.inc/s/contactsupport">Contact Support</a>

                                            <a className="nav-link fs-5 fw-normal footer-link px-0 mb-md-4 text-start" style={{ color: "#fff" }} href="https://downloads.smallhd.com/">Downloads</a>

                                            <a className="nav-link fs-5 fw-normal footer-link px-0 mb-md-4 text-start" style={{ color: "#fff" }} href="https://register.smallhd.com/">Monitor Activation</a>

                                        </nav>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item border-start-0 border-end-0 bg-black border-gray-6">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed fw-bold text-gray-3 text-uppercase fs-7 ls-px-md-112 ls-px-12" style={{ backgroundColor: "#000 !important", borderTop: "1px solid #333 !important;", borderBottom: "1px solid #333 !important" }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        <span className="text-white">Legal</span>
                                    </button>
                                </h2>
                                <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div className="accordion-body" style={{ background: "#000;" }}>
                                        <nav className="nav flex-column">

                                            <a className="nav-link fs-5 fw-normal footer-link px-0 mb-md-4 text-start" style={{ color: "#fff" }} href="https://smallhd.com/pages/privacy-policy">Privacy Policy</a>

                                            <a className="nav-link fs-5 fw-normal footer-link px-0 mb-md-4 text-start" style={{ color: "#fff" }} href="https://smallhd.com/pages/cookie-policy">Cookie Policy</a>

                                            <a className="nav-link fs-5 fw-normal footer-link px-0 mb-md-4 text-start" style={{ color: "#fff" }} href="https://smallhd.com/pages/return-policy">Return Policy</a>

                                            <a className="nav-link fs-5 fw-normal footer-link px-0 mb-md-4 text-start" style={{ color: "#fff" }} href="https://smallhd.com/pages/shipping-policy">Shipping Policy</a>

                                            <a className="nav-link fs-5 fw-normal footer-link px-0 mb-md-4 text-start" style={{ color: "#fff" }} href="https://smallhd.com/pages/terms-and-conditions-of-sale">Sale Terms</a>

                                            <a className="nav-link fs-5 fw-normal footer-link px-0 mb-md-4 text-start" style={{ color: "#fff" }} href="https://smallhd.com/pages/standard-limited-warranty-and-exclusions">Warranty</a>

                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-social-media d-md-flex align-items-end justify-content-between">
                        <div>
                            <a target="_blank" className="me-md-6" href="https://www.instagram.com/smallhd/" aria-label="instagram icon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" /></svg>
                            </a>
                            <a target="_blank" className="me-md-6" href="https://www.facebook.com/SmallHD" aria-label="facebook icon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64h98.2V334.2H109.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H255V480H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z" /></svg>
                            </a>
                            <a target="_blank" className="me-md-6" href="https://www.youtube.com/user/SmallHDVideos" aria-label="youtube icon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z" /></svg>
                            </a>
                            <a target="_blank" className="me-md-6" href="https://twitter.com/SmallHD" aria-label="twitter icon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" /></svg>
                            </a>

                            <a target="_blank" href="https://www.linkedin.com/company/smallhd" aria-label="linkedin icon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" /></svg>
                            </a>
                        </div>
                        <div className="d-flex justify-content-md-end justify-content-between mt-10 mt-md-0 align-items-end" style={{ padding: '0 1.5rem' }}>
                            <div>
                                <p className="text fs-6 d-block d-md-block text-white mb-0 ms-md-6 copyright">Copyright © 2023 SmallHD</p>
                            </div>
                            <div>
                                <button className="d-block d-md-block ms-10 bg-transparent border-0" onClick={scrollToTop} id="back-to-up">
                                    <img className="img-fluid" src="https://cdn.shopify.com/s/files/1/0097/6004/7167/files/back-to-top.png?v=1704367893" style={{ width: "56px" }} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );

    }

}
