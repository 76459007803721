import React, { Component } from 'react';

import InputMask from 'react-input-mask';

import Header from '../common/header';

import Footer from '../common/footer';

import Activation from '../activation';

import InvalidLink from '../activation/invalid-link';

import config from '../../config';

import axios from 'axios';

import queryString from 'query-string';

import { Redirect } from 'react-router-dom';

import '../../fonts/HKGroteskPro-Regular.woff';


class Home extends Component {

    constructor(props) {

        super(props);

        this.state = {
            hideVerified: false,
            hideLooksGood: false,
            linkValid: false,
            showActivation: false,
            keyReady: false,
            showActivationCode: false,
            activationCodeValid: true,

            activation: {
                monitor_token: '',
                serial_no: '',
                purchaser_email: '',
                serial_regex: '',
            }

        };

        this.qs = queryString.parse(window.location.search);
        this.handleActivationCodeChange = this.handleActivationCodeChange.bind(this);
        this.handleActivationCodeSubmitBtn = this.handleActivationCodeSubmitBtn.bind(this);
        this.retryBtn = this.retry.bind(this);

    }

    retry() {

        if (this.state.activationCodeValid == false) {
            document.getElementById("activationCodeInput").removeAttribute('readonly');
            this.setState({
                activationCodeValid: true
            })
            document.getElementById("activationCodeInput").focus();

        }


    }
    handleActivationCodeSubmit(event) {
        event.preventDefault();
    }
    handleActivationCodeChange(event) {
        this.setState({ activationCode: event.target.value });



    }

    handleActivationCodeSubmitBtn() {
        document.getElementById("activationCodeInput").setAttribute('readonly', true);
        let originalActivationCode = this.state.activationCode.replace(/-/g, '').replace(/_/g, '');

        if (originalActivationCode.length == 16) {
            this.setState({ originalActivationCode: originalActivationCode })
        }
        var originalLicense = originalActivationCode;
        if (originalLicense && originalLicense.length == 16) {
            this.validateActivationCode(originalLicense);

        } else {
            this.setState({
                activationCodeValid: false
            })
        }
    }

    validateActivationCode(value) {
        this.setState({
            hideVerified: false,
            hideLooksGood: true,
            linkValid: true,
            showActivationCode: false
        });
        this.setState({

            activationCodeInputClass: 'form-control linear-background',
        });

        document.getElementById("activationCodeInput").setAttribute('readonly', true);

        axios.get(`${config.baseUrl}`, {
            params: {
                action: 'getactivationkeybyactivationcode',
                activation_code: value.toUpperCase()
            }
        }).then((e) => {

            this.setState({
                activationCodeInputClass: 'form-control'
            });

            const response = e.data;

            if (response.status === 'success') {

                const data = response.data || {};

                if (data.activation_status == 'success') {
                    let customer_email = config.getVerifiedEmail();
                    this.qs.key = data.activation_key
                    this.validateActivationKey(customer_email)
                } else if (data.activation_status == 'error') {
                    this.setState({
                        hideVerified: true,
                        hideLooksGood: true,
                        linkValid: false
                    });

                }

                return;

            }

            this.setState({ licenseValid: 'error', licenseInputLabel: this.licenseLabels.error, licenseErrorMessage: this.licenseLabels.errorMessage });

        });
    }

    validateActivationKey(customer_email) {

        axios.get(`${config.baseUrl}`, {
            params: {
                action: 'getverifylicensepurchase',
                activation_key: this.qs.key || '',
                customer_email: customer_email,
            }
        }).then((e) => {

            const response = e.data;

            if (response.status == 'success') {

                const data = response.data || {};

                config.setPurchaserEmail(data.purchaser_email || '');
                config.setLicenseName(data.license_item_display_name || '');
                config.setLicenseItemName(data.license_item_name || '');

                if (data.activation_response === 'Looks good') {

                    this.setState({
                        hideVerified: true, linkValid: true, hideLooksGood: false
                    });


                    config.setActivation({
                        monitor_token: data.monitor_token,
                        serial_no: data.serial_no,
                        purchaser_email: data.purchaser_email,
                        serial_regex: data.serial_regex,
                    });


                    setTimeout(() => {

                        this.setState({
                            hideLooksGood: true,

                        });
                        if (this.qs.key === null || this.qs.key === undefined || this.qs.key === '') {
                            this.setState({
                                showActivation: false
                            })
                        } else {
                            this.setState({
                                showActivation: true
                            })
                        }

                    }, 2000);

                } else if (data.activation_response === 'Not Valid') {
                    this.setState({
                        hideVerified: true,
                        hideLooksGood: true,
                        linkValid: false
                    });
                } else if (data.activation_response === 'Already used') {
                    this.setState({
                        alreadyConsumed: true
                    });
                }

                return;

            }

            this.setState({
                hideVerified: true,
                hideLooksGood: true,
                linkValid: false
            });

        });
    }

    componentDidMount() {
        var customer_email = "";
        /* document.getElementById('listenerFrame').onload = function() {
             var domain = 'https://checkout.na1.netsuite.com';
             var iframe = document.getElementById('listenerFrame').contentWindow;
             var times = 0;
             var inty = setInterval(function(){
                 var message = 'Hello!  The time is: ' + (new Date().getTime());
                 iframe.postMessage(message,domain);
                 if(++times == 1) { clearInterval(inty); }
             },100);
     
     
             //listen to https://checkout.na1.netsuite.com back 
             window.addEventListener('message',function(event) {
                 if(event.origin !== 'https://checkout.na1.netsuite.com') return;
                 var data = event.data;
                 customer_email = data.customer_email.email; //We receive customer_email from NS ERP.
                 console.log(customer_email)
                 config.setVerifiedEmail(customer_email);
     
             });
     
         }*/
        if (this.qs.key === null || this.qs.key === undefined || this.qs.key === '') {
            this.setState({
                showActivationCode: true,
                hideVerified: true,
                hideLooksGood: true,
                linkValid: true,
                showActivation: false
            })
            //	window.open("/standard-activation","_self");
        } else {

            setTimeout(() => {
                this.validateActivationKey(customer_email)

            }, 1000);
        }
    }

    render() {

        if (this.state.alreadyConsumed) {
            return <Redirect to={'key-consumed?key=' + this.qs.key} push />;
        }

        // if (this.state.keyReady) {
        //     return <Redirect to={'key-ready?key=' + this.qs.key} />;
        // }

        if (this.state.showActivation) {
            return <Redirect to={'activate?key=' + this.qs.key} push />;
        }

        return (
            <div className="wrapper active-wrapper">

                <div className="loader" style={{ display: this.state.hideVerified === false ? 'block' : 'none' }}>
                    <div className="validate process text-center">
                        <div className="spinner mb-4">
                            <i className="fa fa-spinner fa-4x" aria-hidden="true"></i>
                        </div>
                        <h2>Validating Purchase</h2>
                    </div>
                </div>

                <div className="validated" style={{ display: this.state.hideLooksGood === false ? 'block' : 'none' }}>
                    <div className="checked process text-center">
                        <div className="mb-4">
                            <i className="fa fa-thumbs-o-up fa-4x" aria-hidden="true"></i>
                        </div>
                        <h2>Looks Good</h2>
                    </div>
                </div>

                <Header />
                <section className="content" style={{ display: this.state.showActivationCode === true ? 'block' : 'none' }}>
                    <div className="container-pf-mini py-md-36 py-27 mt-20 mt-md-0">
                        <div className="row">
                            <div className="col-md mb-7 mb-md-0">
                                <div className="card border-0" style={{ borderRadius: "16px" }}>
                                    <div className="card-body px-md-14 px-5 pt-md-14 pb-md-16 py-15 bg-gray-1" style={{ borderRadius: "16px" }}>
                                        <span className="badge bg-yellow-3 text-black ls-px-12 text-uppercase fs-md-8 mb-md-5 rounded-pill fw-bold px-md-4 px-5 py-2 py-md-1 mb-6">free</span>
                                        <h2 className="fs-2 text-gray-9 mb-md-4 mb-5 text-black">Activate PageOS</h2>
                                        <ul className="ps-0 list-unstyled mb-md-14 mb-15">
                                            <li className='text-black d-flex align-items-md-center mb-2'>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" /></svg>
                                                <span className='ms-2'>Activating here registers your warranty.</span></li>
                                            <li className='text-black d-flex align-items-md-center mb-2'>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" /></svg>
                                                <span className="ms-2">Easy customer support interaction.</span></li>
                                            <li className='text-black d-flex align-items-md-center mb-2'>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" /></svg>
                                                <span className="ms-2">Signs you up for firmware updates (optional).</span></li>
                                        </ul>
                                        <div className="d-flex">
                                            <a href="https://activate.smallhd.com/standard-activation" role="button" className="btn btn-black px-md-6 py-md-4 px-7 py-5 fw-bold fs-md-6 fs-5 me-md-6 me-7">Activate</a>
                                            <a href="https://smallhd.com/pages/os" className="text-black fs-md-6 fs-5 py-md-4 py-5 fw-bold">Learn More About PageOS</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md">
                                <div className="card border-0" style={{ borderRadius: "16px" }}>
                                    <div className="card-body px-md-14 px-5 pt-md-12 pb-md-14 py-15 bg-gray-1" style={{ borderRadius: "16px" }}>
                                        <h2 className="fs-2 text-gray-9 mb-md-4 mb-5">Activate Camera Control</h2>
                                        <p className="mb-2 text-black">Activate Camera Control on your compatible <br /> SmallHD monitor for ARRI, RED®, or <br />
                                            Sony VENICE Cameras.</p>
                                        <a href="https://store.smallhd.com/collections/camera-control" className="text-black fs-md-6 fs-5 py-md-4 py-5 fw-bold d-block mb-md-8 mb-10">Learn More About Camera Control</a>
                                        <label htmlFor="activationCodeInput" className="d-block fs-md-8 fs-7 fw-bold text-uppercase ls-px-12 text-black mb-2"><span className='opacity-05 fw-bold'>Enter Code</span>*</label>
                                        <div className="d-flex">
                                            <InputMask id="activationCodeInput" className={'form-control ' + this.state.activationCodeValid === false ? ' form-control text-uppercase bg-white' : 'text-uppercase form-control bg-white' + ' ' + this.state.activationCodeInputClass} type="text" placeholder="____-____-____-____" mask='****-****-****-****' value={this.state.activationCode} onChange={this.handleActivationCodeChange} />
                                            <button style={{ display: this.state.activationCodeValid === true ? 'block' : 'none' }} onClick={this.handleActivationCodeSubmitBtn} className="btn btn-black px-md-17 px-7 py-5 fs-md-6 fs-5 fw-bold ms-md-6 ms-5" type="button">Go</button>
                                            <button id="clearSerial" style={{ display: this.state.activationCodeValid === false ? 'block' : 'none' }} onClick={this.retryBtn} className="btn btn-danger" type="button"><i className="fa fa-times"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <InvalidLink linkValid={this.state.linkValid} />


            </div>
        );
    }
}

export default Home;
