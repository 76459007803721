import React, { Component } from 'react';

import queryString from 'query-string';

import Contact from '../common/contact';

import { Redirect } from "react-router-dom";

export default class InvalidLink extends Component {

    constructor(props) {
        super(props);

        this.state = {    };
        this.qs = queryString.parse(window.location.search);

        this.openModal = this.openModal.bind(this); 
    }

    openModal() {
       
        const modalVisible = !this.state.modalVisible;
        if(modalVisible) {
            document.body.classList.add('modal-open');
        }
        else
        {
            document.body.classList.remove('modal-open');
        }
        this.setState({
          modalVisible
        });
      }

    render() {
        
        
        return this.props.linkValid ? '' : (
            <div>
            <section className="content flex-center invalid ">
                <div className="container-fluid">
                    <div className="text-center">
                        <i className="fa fa-exclamation-circle fa-4x mb-4" aria-hidden="true"></i>
                        <h2 className="mb-3">Uh-Oh!</h2>
                        <h3 className="mb-5">That is not a valid activation link</h3>

                        <p className="small dark bold">For any assistance. <a href="javascript:;" onClick={this.openModal} className="dark bold"><ins>Contact Support</ins></a></p>
                    </div>
                </div>                
            </section>
            <Contact 
            openModal = { this.openModal }
            modalVisible = { this.state.modalVisible }
        />
        </div>
        );
    }

}