import React, { Component } from 'react';

import InputMask from 'react-input-mask';

import Footer from '../common/footer';

import config from '../../config';

import axios from 'axios';

import { Redirect } from 'react-router-dom';

// ani: file:///D:/projects/License%20App/html/validate-token.html

import queryString from 'query-string';
import Header from '../common/header';
import platform from 'platform';

import '../../css/bootstrap.min.css';
import '../../css/bs5-global-custom.css';
export default class Activation extends Component {

    constructor(props) {

        super(props);

        this.licenseLabels = {
            init: 'Enter Monitor Token',
            validating: 'Validating Monitor Token...',
            valid: 'Monitor Token Validated',
            error: 'Invalid Monitor Token',
            errorMessage: 'You may have entered a wrong license token, please try again or contact support for assistance.',
            serialErrorMessage: 'The serial number does not look valid or does not match with Monitor Token',
        };

        this.qs = queryString.parse(window.location.search)

        this.state = {
            licenseToken: '',
            serialKey: '',
            serialRegex: '',
            licenseValid: '', // error,valid or empty
            licenseInputLabel: this.licenseLabels.init,
            serialValid: '',
            redirectContact: false,
            licenseValidModal: 'error',
        };

        this.handleLicenseChange = this.handleLicenseChange.bind(this);
        this.handleLicenseSubmit = this.handleLicenseSubmit.bind(this);

        this.handleLicenseSubmitBtn = this.handleLicenseSubmitBtn.bind(this);

        this.validateLicenseTokenBtn = this.validateLicenseTokenBtn.bind(this);


        this.serialKeyChange = this.handleSerialKeyChange.bind(this);

        this.handleSerialEnter = this.handleSerialEnter.bind(this);

        this.enterSerial = this.clickSerialEnter.bind(this);
        this.retryBtn = this.retry.bind(this);

        this.skipSerial = this.skipSerial.bind(this);

        this.contactBtn = this.contact.bind(this);

        this.handleSerialFocus = this.focusSerialInput();

        this.openModal = this.openModal.bind(this);

    }

    contact() {
        this.setState({
            redirectContact: '/contact?key=' + this.qs.key
        });
    }

    handleLicenseChange(event) {
        this.setState({ licenseToken: event.target.value });

        let originalLicense = event.target.value.replace(/-/g, '').replace(/_/g, '');

        if (originalLicense.length == 14) {
            this.setState({
                modalVisible: true

            })
        }

        this.setState({ licenseValidModal: 'error', })

        //  this.validateLicenseToken(originalLicense.toUpperCase());

    }

    handleLicenseSubmitBtn() {
        var originalLicense = this.state.licenseToken;
        originalLicense = originalLicense.replace(/-/g, '').replace(/_/g, '');
        if (originalLicense.length == 14) {
            this.setState({
                modalVisible: true,
                licenseValidModal: 'error',

            })
        }
    }

    validateLicenseTokenBtn() {
        var originalLicense = this.state.licenseToken;
        originalLicense = originalLicense.replace(/-/g, '').replace(/_/g, '');
        this.setState({
            modalVisible: false,
        })
        this.validateLicenseToken(originalLicense.toUpperCase());
    }
    handleSerialKeyChange(event) {
        this.setState({ serialKey: event.target.value });
    }

    handleLicenseSubmit(event) {
        event.preventDefault();
    }

    handleSerialEnter(event) {
        if (event.key === 'Enter') {

            if (this.state.serialKey == 'C7AS190930009') {
                this.setState({
                    serialValid: 'error',
                    serialErrorMessage: this.licenseLabels.serialErrorMessage
                });
                return;
            }
            // if regex avialable
            if (this.state.serialRegex.length) {
                let expr = new RegExp(this.state.serialRegex);
                if (!expr.test(this.state.serialKey.toUpperCase())) {

                    this.setState({
                        serialValid: 'error',
                        serialErrorMessage: this.licenseLabels.serialErrorMessage
                    });
                    document.getElementById("serialKeyInput").setAttribute('readonly', true);
                    return;
                }
            }

            this.validateSerialToken(this.state.serialKey.toUpperCase());
        }
    }

    clickSerialEnter(event) {

        if (this.state.serialKey == 'C7AS190930009') {
            this.setState({
                serialValid: 'error',
                serialErrorMessage: this.licenseLabels.serialErrorMessage
            });
            return;
        }
        // if regex avialable
        if (this.state.serialRegex.length) {
            let expr = new RegExp(this.state.serialRegex);
            if (!expr.test(this.state.serialKey.toUpperCase())) {

                this.setState({
                    serialValid: 'error',
                    serialErrorMessage: this.licenseLabels.serialErrorMessage
                });
                document.getElementById("serialKeyInput").setAttribute('readonly', true);
                return
            }
        }

        this.validateSerialToken(this.state.serialKey.toUpperCase());

    }

    componentDidMount() {
        const activation = config.getActivation();

        this.setState({
            licenseToken: activation.monitor_token || '',
            serialKey: activation.serial_no || '',
            serialRegex: activation.serial_regex || '',
        });

        setTimeout(() => {
            if (activation.monitor_token && activation.monitor_token.length) {
                this.validateLicenseToken(activation.monitor_token.toUpperCase());
            }
        }, 500)

    }

    componentDidUpdate(prevProps) {

    }

    skipSerial() {
        this.setState({
            redirect: 'key-ready?key=' + this.qs.key
        });
    }

    validateSerialToken(value) {

        document.getElementById("serialKeyInput").setAttribute('readonly', true);

        this.setState({
            serialInputClass: 'form-control linear-background'
        });

        axios.get(`${config.baseUrl}`, {
            params: {
                action: 'getverifyserial',
                activation_key: this.qs.key || '', //'AB12CD45EF67', // this will be from url
                serial: value.toUpperCase()
            }
        }).then((e) => {

            this.setState({
                serialInputClass: 'form-control'
            });

            const response = e.data;

            if (response.status === 'success') {

                const data = response.data || {};

                // console.log(data);

                if (data.activation_response == true) {
                    this.setState({
                        serialValid: 'valid',
                        redirect: 'key-ready?key=' + this.qs.key
                    });
                    return
                } if (data.activation_response == false) {
                    this.setState({
                        serialValid: 'error',
                        serialErrorMessage: data.activation_status_text
                    });
                    return
                }

            }

            this.setState({ serialValid: 'error' });

        });
    }

    async validateLicenseToken(value) {

        this.setState({
            licenseInputLabel: this.licenseLabels.validating,
            licenseInputClass: 'form-control linear-background',
        });

        document.getElementById("licenseTokenInput").setAttribute('readonly', true);
        const OS = platform.os.family
        const browser = platform.name
        let ip = await axios.get('https://api.ipify.org/?format=json')
        ip = ip.data.ip
        axios.get(`${config.baseUrl}`, {
            params: {
                action: 'getvalidatemonitortoken',
                activation_key: this.qs.key || '', //'AB12CD45EF67', // this will be from url
                monitor_token: value.toUpperCase(),
                item_name: config.getLicenseItemName() || '',
                ip,
                OS,
                browser
            }
        }).then((e) => {

            this.setState({
                licenseInputClass: 'form-control'
            });

            const response = e.data;

            if (response.status === 'success') {

                const data = response.data || {};

                if (data.activation_response == 'Valid') {
                    this.setState({
                        licenseValid: 'valid',
                        licenseInputLabel: this.licenseLabels.valid,
                    });
                } else if (data.activation_response == 'Error') {
                    this.setState({
                        licenseValid: 'error',
                        licenseInputLabel: this.licenseLabels.error,
                        licenseErrorMessage: data.activation_status_text,
                    });
                    return
                }
                else if (data.activation_response == 'Already active') {
                    this.setState({
                        licenseValid: 'error',
                        licenseInputLabel: this.licenseLabels.valid,
                        licenseErrorMessage: data.activation_status_text,
                    });
                    return
                }

                if (data.monitor_serial_no && data.monitor_serial_no.length) {

                    this.setState({
                        serialKey: data.monitor_serial_no,
                        redirect: 'key-ready?key=' + this.qs.key
                    });

                    //this.validateSerialToken(data.monitor_serial_no);

                }

                return;

            }

            this.setState({ licenseValid: 'error', licenseInputLabel: this.licenseLabels.error, licenseErrorMessage: this.licenseLabels.errorMessage });

        });
    }

    focusSerialInput() {
        //console.log('tokeen focused');
        if (this.state.serialValid === 'error') {
            document.getElementById('clearSerial').click();
        }
    }

    retry() {

        if (this.state.licenseValid == 'error') {
            return this.retryLicenseToken();
        }

        this.retrySerailNumber();
    }

    retryLicenseToken() {

        document.getElementById("licenseTokenInput").removeAttribute('readonly');

        this.setState({
            licenseToken: '',
            licenseValid: '',
            licenseInputLabel: this.licenseLabels.init
        });

        document.getElementById("licenseTokenInput").focus();
    }

    retrySerailNumber() {

        document.getElementById("serialKeyInput").removeAttribute('readonly');

        this.setState({
            serialKey: '',
            serialValid: '',
        });

        document.getElementById("serialKeyInput").focus();
    }

    openModal() {

        const modalVisible = !this.state.modalVisible;
        if (modalVisible) {
            document.body.classList.add('modal-open');
            this.setState({
                licenseValidModal: 'error',
            });
        }
        else {
            document.body.classList.remove('modal-open');
            this.setState({
                licenseValidModal: '',
            });
        }
        this.setState({
            modalVisible
        });
    }

    render() {

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />;
        }


        if (this.state.redirectContact) {
            return (<Redirect to={this.state.redirectContact} push />);
        }

        let modalStyles = this.state.modalVisible
            ? { display: "block" }
            : { display: "none" };
        let modalClass = this.state.modalVisible ? "show" : ""

        return (
            <div className="wrapper">
                <Header></Header>
                <section className="content">
                    <div className="container-pf-blogs py-md-27 py-20">
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <h3 className='fw-medium'>Activating {config.getLicenseName()}</h3>

                                <div className="step mb-4">
                                    <p>Step 1 of 3</p>
                                </div>

                                <div className="form">

                                    <form>
                                        <fieldset className={this.state.licenseValid}>
                                            <h5 className="mb-3 fw-medium">{this.state.licenseInputLabel}</h5>
                                            <div className="input-group">
                                                <InputMask id="licenseTokenInput" className={'form-control rounded-3' + this.state.licenseValid === 'error' ? ' form-control rounded-3' : 'rounded-3 form-control ' + ' ' + this.state.licenseInputClass} type="text" placeholder="..." mask='**-**-**-**-**-**-**' value={this.state.licenseToken} onChange={this.handleLicenseChange} />
                                                <button style={{ display: this.state.licenseValidModal === 'error' ? 'none' : 'block' }} onClick={this.handleLicenseSubmitBtn} className="btn btn-primary rounded-3" type="button">Submit</button>
                                                <button id="clearToken" style={{ display: this.state.licenseValid === 'error' ? 'block' : 'none' }} onClick={this.retryBtn} className="btn btn-danger" type="button"><i className="fa fa-times"></i></button>
                                            </div>
                                            <p style={{ display: this.state.licenseValid === 'error' ? 'block' : 'none' }} className="mt-3 small">{this.state.licenseErrorMessage}</p>
                                        </fieldset>

                                        <fieldset className={'mb-2 ' + this.state.serialValid} style={{ display: this.state.licenseValid === 'valid' ? 'block' : 'none' }}>
                                            <h5 className="mb-3 fw-medium">Enter your monitor's Serial Number</h5>
                                            <div className="input-group">
                                                <input id="serialKeyInput" className={'form-control ' + this.state.serialValid === 'error' ? ' form-control ' : ' form-control rounded-3' + ' ' + this.state.serialInputClass} type="text" placeholder="..." value={this.state.serialKey} onChange={this.serialKeyChange} onKeyDown={this.handleSerialEnter} />
                                                <div className="input-group-append">
                                                    <button style={{ display: this.state.serialValid === 'error' ? 'none' : 'block' }} onClick={this.enterSerial} className="btn btn-primary rounded-3" type="button">Submit</button>
                                                    <button id="clearSerial" style={{ display: this.state.serialValid === 'error' ? 'block' : 'none' }} onClick={this.retryBtn} className="btn btn-danger" type="button"><i className="fa fa-times"></i></button>
                                                </div>
                                            </div>
                                            <p style={{ display: this.state.serialValid === 'error' ? 'block' : 'none' }} className="mt-3 small">{this.state.serialErrorMessage}</p>
                                            <a className="mt-3" href="javascript:;" onClick={this.skipSerial}> Skip this step &raquo; </a>
                                        </fieldset>

                                    </form>
                                </div>

                                <div className="serial-detail" style={{ display: this.state.licenseValid === 'valid' ? 'block' : 'none' }}>
                                    <h5 className="mt-4 fw-medium">
                                        <span className="mr-1">
                                            <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                                        </span>Where to find the Serial Number</h5>
                                    <div className="text-center video-wrapper linear-background mb-5" style={{ display: this.state.licenseValid === 'valid' ? 'block' : 'none' }}>
                                        <video src="images/Cine7-SerialLocation.mp4" autoPlay={true} controls={true} loop={true} className="linear-background w-100 video-style" />

                                    </div>
                                </div>

                                <div className="token-detail" style={{ display: this.state.licenseValid === '' ? 'block' : 'none' }}>
                                    <h5 className='fw-medium'>
                                        <span className="mr-1 fw-medium">
                                            <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                                        </span>Where to find the Monitor Token</h5>
                                    <p className='fs-6'>Navigate to the following screen on your SmallHD monitor which you intend to use with this software upgrade.</p>
                                    <div className="text-center video-wrapper linear-background">
                                        <video src="images/MonitorToken_IRL.mp4" autoPlay={true} controls={true} loop={true} className="linear-background w-100 video-style" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
                <div className={`modal fade in ` + modalClass} style={modalStyles} tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Are you sure?</h5>

                            </div>
                            <div className="modal-body ">
                                <p className="text-dark">We will verify your monitor token and save it permanently to produce the License. This action is not reversible so we recommend to make sure that you enter the correct monitor's token.</p>
                            </div>
                            <div className="modal-footer">
                                <a href="javascript:;" onClick={this.openModal} className="text-primary mr-3">No, I'll take another look </a>
                                <button className="btn btn-primary" onClick={this.validateLicenseTokenBtn}>Yes</button>

                            </div>
                        </div>
                    </div>

                </div>
                <div className={`modal-backdrop fade ` + modalClass} style={modalStyles}></div>
            </div>
        );
    }

}