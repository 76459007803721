import React, { Component } from 'react';

import Header from './header';

import queryString from 'query-string';

export default class Contact extends Component {

    constructor(props) {

        super(props);

        this.qs = queryString.parse(window.location.search);     

       
    };    
   
    componentDidMount(props) {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.innerHTML = '(function(o,l,a,r,k,y){if(o.olark)return; r="script";y=l.createElement(r);r=l.getElementsByTagName(r)[0]; y.async=1;y.src="//"+a;r.parentNode.insertBefore(y,r); y=o.olark=function(){k.s.push(arguments);k.t.push(+new Date)}; y.extend=function(i,j){y("extend",i,j)}; y.identify=function(i){y("identify",k.i=i)}; y.configure=function(i,j){y("configure",i,j);k.c[i]=j}; k=y._={s:[],t:[+new Date],c:{},l:a}; })(window,document,"static.olark.com/jsclient/loader.js"); olark.identify("8287-194-10-7340");'+
        'olark("api.box.hide");' +
        'olark("api.box.onShrink", function() { olark("api.box.hide") });' +
        'document.getElementById("openchat").addEventListener("click", function() {  olark("api.box.expand")})     ';
       
        document.body.appendChild(s)       
       
      }

    render() {

        let modalStyles = this.props.modalVisible
      ? { display: "block" }
      : { display: "none" };
      let modalClass = this.props.modalVisible ? "show" : ""

        return (
            <div className="contact-modal">
            <div className={`modal fade in ` + modalClass }   style={modalStyles} tabIndex="-1" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                            <div className="modal-header">
                                
                                <button type="button" className="close" onClick={this.props.openModal} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                        <div className="text-center">
                            <h2 className="mb-5 contact-title dark"> Need help?</h2>
                            <a href="javascript:;" id="openchat" onClick={this.props.openModal} className="btn-primary dark mb-4"><i className="fa fa-commenting-o" aria-hidden="true"></i> Chat with us</a>

                            <div className="contact-detail p-4">
                                <h3 className="light">Call us</h3>
                                <h3 className="mb-0"><a href="tel:9194392166" className="bold dark"><ins>919.439.2166</ins></a></h3>
                            </div>

                            <div className="contact-detail p-4">
                                <h3 className="light">Write to us</h3>
                                <h3 className="mb-0"><a href="mailto:support@smallhd.com" className="bold dark"><ins>support@smallhd.com</ins></a></h3>
                            </div>
                        </div>
                    </div>
                    </div>
            </div>
            </div>
            <div className={`modal-backdrop fade ` + modalClass }  style={modalStyles}></div>
            </div>
        );
    }
}