// file:///D:/projects/License%20App/html/key-resent.html

import React, { Component } from 'react';

import Header from '../common/header';

import Footer from '../common/footer';

import Contact from '../common/contact';

import config from '../../config';

import queryString from 'query-string';

import axios from 'axios';

import { Redirect } from "react-router-dom";

export default class KeyResent extends Component {

    constructor(props) {
        super(props);

        this.qs = queryString.parse(window.location.search);

        this.state = {
            needsEmailVerification: true,
            verifiedEmail: config.getVerifiedEmail() || '',
            verifyEmailInputClass: '',
            emailError: '',

            redirectContact: false
        }

        this.verifyEmailBtn = this.verifyEmail.bind(this);
        this.onVerificationEmailChange = this.verificationEmailChanged.bind(this);

        this.handleEmailEnter = this.handleEmailEnter.bind(this);

        this.openModal = this.openModal.bind(this);
    }

    openModal() {

        const modalVisible = !this.state.modalVisible;
        if (modalVisible) {
            document.body.classList.add('modal-open');
        }
        else {
            document.body.classList.remove('modal-open');
        }
        this.setState({
            modalVisible
        });
    }


    verificationEmailChanged(event) {
        this.setState({ verifiedEmail: event.target.value });
    }

    handleEmailEnter(event) {
        if (event.key === 'Enter') {
            this.verifyEmail();
        }
    }

    verifyEmail() {

        /*  if (!this.state.verifiedEmail.length) {
              this.setState({
                  varifiedEmailValid: 'error'
              });
  
              return;
          }*/

        this.sendLicenseKey();

        //  config.setVerifiedEmail(this.state.verifiedEmail);

    }

    sendLicenseKey() {

        this.setState({
            verifyEmailInputClass: 'linear-background'
        });

        axios.get(`${config.baseUrl}`, {
            params: {
                action: 'getgeneratelincesekey',
                activation_key: this.qs.key || '',
                additional_emails: this.state.verifiedEmail,
                // phone: this.state.phone,
                verify_email: config.getVerifiedEmail() || '',
                customer_email: config.getVerifiedEmail() || config.getPurchaserEmail() || ''
            }
        }).then((e) => {

            const response = e.data;

            if (response.status == 'success') {

                const data = response.data || {};

                if (data.activation_status === 'Case 3') {
                    config.setLicenseKey(data.license_key);
                    this.setState({
                        keySent: true
                    });
                    return;
                }

                if (data.activation_status == 'Case 2') {
                    config.setLicenseKey(data.license_key);
                    this.setState({
                        needsEmailVerification: false
                    });
                    return;
                }

                if (data.activation_status == 'Case 1') {
                    this.setState({
                        varifiedEmailValid: 'error',
                        verifyEmailInputClass: '',
                        emailError: data.activation_response,
                        needsEmailVerification: true,
                    });
                    return;
                }

                this.setState({
                    needsEmailVerification: false
                });

                return;

            }

        });
    }

    render() {

        return (

            <div className="wrapper resend-success">

                <Header></Header>
                <div ref={el => (this.instance = el)} > </div>
                <section className="content" style={{ display: this.state.needsEmailVerification ? 'block' : 'none' }}>
                    <div className="container-pf-mini pt-md-27 pb-md-34 py-20">
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <h2 className="mb-5 fw-medium">Resend License Key</h2>

                                <fieldset className={this.state.varifiedEmailValid}>
                                    <p className="dark bold">Email will be sent to <span className="light">{config.getPurchaserEmail()}</span></p>
                                    <h4 className="text-center mt-4 fw-medium">Additional Recepients</h4>
                                    <input className={this.state.verifyEmailInputClass} type="email" placeholder="Enter emails seperated by comma..." onChange={this.onVerificationEmailChange} onKeyDown={this.handleEmailEnter} />
                                </fieldset>
                                <p className="small mt-3 fs-6" style={{ display: this.state.varifiedEmailValid === 'error' ? 'block' : 'none' }}>{this.state.emailError || 'The email you entered does not look valid. Try again.'}</p>


                                <fieldset className="mt-4">
                                    <a onClick={this.verifyEmailBtn} href="javascript:;" className="btn btn-violet-3 text-center fs-5 fs-md-6 fw-bold px-6 py-4">Resend <i className="fa fa-paper-plane-o ml-2" aria-hidden="true"></i></a>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="content flex-center" style={{ display: !this.state.needsEmailVerification ? 'flex' : 'none' }}>
                    <div className="container-pf-mini py-md-27 py-20">
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <div className="text-center">
                                    <i className="fa fa-paper-plane-o mb-4 fa-4x" aria-hidden="true"></i>
                                    <h3>Here is your license Key!</h3>
                                    <h3 className="mt-4"><span className="license-key">{config.getLicenseKey()}</span></h3>
                                </div>

                                <div className="text-center process-detail">
                                    <h2 className="mb-4 fw-bold">Whats Next?</h2>
                                    <p className="mb-2 small dark bold pt-2">Activate the license on your monitor. Here is how:</p>
                                    <div className="detail my-3 video-wrapper linear-background">
                                        <video src="images/LicenseEntry_IRL.mp4" autoPlay={true} controls={true} loop={true} className="w-100 video-style" />
                                    </div>
                                    <p className="mt-2 small fs-6 dark bold">For any assistance. <a onClick={this.openModal} href='javascript:;' className="dark bold"><ins>Contact Support</ins></a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <Contact
                    openModal={this.openModal}
                    modalVisible={this.state.modalVisible}
                />
            </div>

        );
    }

}