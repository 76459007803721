//file:///D:/projects/License%20App/html/additional-recepients.html

import React, { Component } from 'react';


import axios from 'axios';

import config from '../../config';

import Header from '../common/header';

import Footer from '../common/footer';

import { Redirect } from 'react-router-dom'

import queryString from 'query-string';

export default class KeyReady extends Component {


    constructor(props) {
        super(props);

        this.sendBtnTexts = {
            send: 'Send',
            sendWith: 'Send with additional recepients',
        };

        this.state = {
            sendBtnTxt: this.sendBtnTexts.send,
            needsEmailVerification: true,
            verifiedEmail: config.getVerifiedEmail() || '',
            varifiedEmailValid: '',
            additionalEmails: '',
            phone: '',
            customerEmail: config.getVerifiedEmail() || config.getPurchaserEmail() || '',
            customerName: '',

            keySent: false,
            checked: true,
            modalVisible: false,
        };


        this.verifyEmailBtn = this.verifyEmail.bind(this);
        this.onVerificationEmailChange = this.verificationEmailChanged.bind(this);


        this.onAdditionalEmailsChange = this.additionalEmailsChanged.bind(this);
        this.onPhoneChange = this.phoneChanged.bind(this);

        this.additionalEmailsBtn = this.additionalEmails.bind(this);

        this.handleEmailEnter = this.handleEmailEnter.bind(this);

        this.qs = queryString.parse(window.location.search);
        this.onCustomerEmailChange = this.customerEmailChanged.bind(this);
        this.onCustomerNameChange = this.customerNameChanged.bind(this);

        // this.termsBtn = this.terms.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.openModal = this.openModal.bind(this);

    }
    openModal() {

        const modalVisible = !this.state.modalVisible;
        if (modalVisible) {
            document.body.classList.add('modal-open');
        }
        else {
            document.body.classList.remove('modal-open');
        }
        this.setState({
            modalVisible
        });
    }
    /* terms() {
         this.setState({
             redirectTerms: '/terms?key=' + this.qs.key
         });
     }*/

    customerEmailChanged(event) {
        this.setState({ customerEmail: event.target.value });
    }

    customerNameChanged(event) {
        this.setState({ customerName: event.target.value });
    }

    verificationEmailChanged(event) {
        this.setState({ verifiedEmail: event.target.value });
    }

    additionalEmailsChanged(event) {

        this.setState({ additionalEmails: event.target.value });

        // if (!this.state.additionalEmails.length) {
        //     return this.setState({
        //         sendBtnTxt: this.sendBtnTexts.send
        //     });
        // }

        // this.setState({
        //     sendBtnTxt: this.sendBtnTexts.sendWith
        // });
    }

    phoneChanged(event) {
        this.setState({ phone: event.target.value });
    }

    handleEmailEnter(event) {
        if (event.key === 'Enter') {
            this.verifyEmail();
        }
    }
    handleCheck() {
        this.setState({ checked: !this.state.checked });
        this.setState({
            termsValid: 'valid'
        });
    }
    verifyEmail() {

        if (!this.state.verifiedEmail.length) {
            this.setState({
                varifiedEmailValid: 'error'
            });

            return;
        }

        config.setVerifiedEmail(this.state.verifiedEmail);
        this.sendLicense();
    }

    additionalEmails() {
        if (this.state.phone.length) {
            if (this.state.phone.length != 10 || !/[0-9]/.test(this.state.phone)) {
                this.setState({
                    phoneValid: 'error'
                });
                return;
            }

            this.setState({
                phoneValid: 'valid'
            });
        }
        if (!this.state.checked) {
            this.setState({
                termsValid: 'error'
            });
            return;
        }
        else {
            this.setState({
                termsValid: 'valid'
            });
        }
        if (this.state.customerName.length) {
            this.setState({
                customerNameValid: 'valid',
                customerName: this.state.customerName
            })
        } else {
            this.setState({
                customerNameValid: 'error'
            })
            return
        }

        if (this.state.customerEmail.length) {
            if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.state.customerEmail)) {
                this.setState({
                    customerEmailValid: 'valid'
                })
                config.setVerifiedEmail(this.state.customerEmail)
                config.setPurchaserEmail(this.state.customerEmail)
            } else {
                this.setState({
                    customerEmailValid: 'error'
                });
                return;
            }

        } else {
            this.setState({
                customerEmailValid: 'error'
            });
            return;
        }


        var verifiedEmailexist = config.getVerifiedEmail();
        if (verifiedEmailexist) {
            this.sendLicense();
            return;
        }
        this.setState({
            needsEmailVerification: false
        });
        return;
    }

    sendLicense() {
        console.log('hello')
        axios.get(`${config.baseUrl}`, {
            params: {
                action: 'getgeneratelincesekey',
                activation_key: this.qs.key || '',
                additional_emails: this.state.additionalEmails,
                phone: this.state.phone,
                verify_email: this.state.verifiedEmail || config.getVerifiedEmail(),
                customer_email: this.state.verifiedEmail || config.getVerifiedEmail(),
                customer_name: this.state.customerName
            }
        }).then((e) => {

            const response = e.data;

            if (response.status == 'success') {

                const data = response.data || {};

                if (data.activation_status === 'Case 3') {
                    config.setLicenseKey(data.license_key);
                    this.setState({
                        keySent: true
                    });
                    return;
                }

                if (data.activation_status === 'Case 2') {
                    config.setLicenseKey(data.license_key);
                    this.setState({
                        keySent: true
                    });
                    return;
                }

                if (data.activation_status === 'Case 1') {
                    this.setState({
                        varifiedEmailValid: 'error',
                        verifyEmailInputClass: '',
                        emailError: data.activation_response,
                        needsEmailVerification: false,
                    });
                    config.setVerifiedEmail('');
                    return;
                }

                return;

            }

        });

    }

    render() {

        if (this.state.redirectTerms) {
            return (<Redirect to={this.state.redirectTerms} push />);
        }

        if (this.state.keySent) {
            return <Redirect to={'/sent?key=' + this.qs.key} push />
        }

        let modalStyles = this.state.modalVisible
            ? { display: "block" }
            : { display: "none" };
        let modalClass = this.state.modalVisible ? "show" : ""

        return (
            <div className="wrapper">

                <Header></Header>

                <section className="content">
                    <div className="container-pf-mini py-md-27 py-20">
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <h3 className='fw-medium'>Activating {config.getLicenseName()}</h3>

                                <div className="step mb-4">
                                    <p>Step 3 of 3</p>
                                </div>

                                <div style={{ display: this.state.needsEmailVerification === false ? 'none' : 'block' }}>

                                    <h5 className="mb-4 fw-medium">Your License Key is ready and will be sent to your email</h5>
                                    <fieldset className={'mt-4 ' + this.state.varifiedEmailValid}>
                                        <h5 className="mb-3 fw-medium">Name <span className="light"></span></h5>
                                        <input type="text" placeholder="Enter name..." onChange={this.onCustomerNameChange} />
                                    </fieldset>
                                    <p className="small fs-6 mt-3" style={{ display: this.state.customerNameValid === 'error' ? 'block' : 'none' }}>Please enter your name.</p>
                                    <fieldset className={'mt-4 ' + this.state.varifiedEmailValid}>
                                        <h5 className="mb-3 fw-medium">Email</h5>
                                        <input type="Email" placeholder="Enter email..." defaultValue={config.getPurchaserEmail()} onChange={this.onCustomerEmailChange} />

                                    </fieldset>
                                    <p className="small fs-6 mt-3 fs-6" style={{ display: this.state.customerEmailValid === 'error' ? 'block' : 'none' }}>The email you entered does not look valid.</p>
                                    <h4 className="text-center mt-4 fw-medium">Additional Recepients</h4>
                                    <p className='fs-5'>Use the option if you are not the original purchaser of the software and is required by purchaser to activate the monitor. Leave the fields blank if not needed.</p>
                                </div>


                                <div className="form mb-0" style={{ display: this.state.needsEmailVerification === true ? 'none' : 'block' }}>

                                    <fieldset className={this.state.varifiedEmailValid}>
                                        <h5 className="mb-3 fw-medium">Verify email </h5>
                                        <input type="Email" placeholder="Enter email..." onChange={this.onVerificationEmailChange} onKeyDown={this.handleEmailEnter} />
                                    </fieldset>

                                    <p className="small fs-6 mt-3" style={{ display: this.state.varifiedEmailValid === 'error' ? 'block' : 'none' }}>The email you entered does not look valid. Try again.</p>


                                    <fieldset className=" mt-4">
                                        <button onClick={this.verifyEmailBtn} type="button" className="text-center btn btn-violet-3 fs-md-6 fs-5 fw-bold px-6 py-4">Send <i className="fa fa-paper-plane-o ml-2" aria-hidden="true"></i></button>
                                    </fieldset>

                                </div>

                                <div className="form mb-0" style={{ display: this.state.needsEmailVerification === false ? 'none' : 'block' }}>
                                    <form>
                                        <fieldset className="">
                                            <h5 className="mb-3 fw-medium">Additional Email Recepients (Optional)</h5>
                                            <input type="Email" placeholder="Enter emails seperated by comma..." onChange={this.onAdditionalEmailsChange} />
                                        </fieldset>

                                        <fieldset className={'mb-2 d-none ' + this.state.phoneValid}>
                                            <h5 className="mb-3 fw-medium">Phone (Optional)</h5>
                                            <input type="tel" placeholder="..." onChange={this.onPhoneChange} max="10" />
                                        </fieldset>
                                        <p style={{ display: this.state.phoneValid == 'error' ? 'block' : 'none' }}>The phone you entered does not look valid.</p>
                                        <div className={this.state.termsValid == 'error' ? 'form-check fs-6 error' : 'form-check fs-6'}>
                                            <input type="checkbox" className={this.state.termsValid == 'error' ? 'form-check-input terms-input error' : 'form-check-input terms-input'} id="terms" name="terms" defaultChecked={this.state.checked} onChange={this.handleCheck} />
                                            <label className="form-check-label term-e" htmlFor="terms">I have read and agree to the <a className="text-primary" onClick={this.openModal} href='javascript:;'>terms of service</a></label>
                                            <p className="small fs-6 mt-3 fs-6" style={{ display: this.state.termsValid === 'error' ? 'block' : 'none' }}>Please check before proceeding.</p>
                                        </div>
                                        <fieldset className="submit mb-0">
                                            <button onClick={this.additionalEmailsBtn} type="button" className="btn-block btn btn-violet-3 text-center fs-5 fs-md-6 fw-bold px-6 py-4">{this.state.additionalEmails.length ? this.sendBtnTexts.sendWith : this.sendBtnTexts.send} <i className="fa fa-paper-plane-o ml-2" aria-hidden="true"></i></button>
                                        </fieldset>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`modal fade in ` + modalClass} style={modalStyles} tabIndex="-1" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">End User License Agreement</h5>
                                    <button type="button" className="close" onClick={this.openModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <ol type="1">
                                        <li>Introduction and Acceptance. This End User License Agreement (this “Agreement”), including any purchase order that references this Agreement (or effective version) and which is hereby incorporated herein (“Purchase Order”), is a legal agreement between each end user licensee (herein referenced as “you” or with “your”) and small fs-6HD LLC regarding the use of one or more small fs-6HD software products referenced in a Purchase Order that accompany or are provided pursuant this Agreement, which such products include all Updates (as defined below) and user documentation provided in “online” or electronic form (collectively, the “Software”), as well as any associated services provided by small fs-6HD in connection with the Software (the “Services”). You and small fs-6HD are each a “party” and together the “parties” to this Agreement. BY CLICKING THE BUTTON THAT INDICATES YOUR ACCEPTANCE AND ACTIVATING THE SOFTWARE WITH ANY PRODUCT KEY OR OTHER AUTHORIZING CODE PROVIDED BY small fs-6HD, WHICHEVER IS EARLIER, YOU AGREE TO BE BOUND BY AND TO BECOME A PARTY TO THIS AGREEMENT. BY ENTERING INTO THIS AGREEMENT, YOU REPRESENT AND WARRANT THAT: (A) YOU ARE 18 YEARS OF AGE OR OLDER; AND (II) IF YOU ARE AN ENTITY, ORGANIZATION, OR COMPANY, THE INDIVIDUAL ACCEPTING THESE TERMS ON YOUR BEHALF HAS AUTHORITY TO BIND YOUR ENTITY, ORGANIZATION, OR COMPANY TO THESE TERMS AND SUCH ENTITY, ORGANIZATION, OR COMPANY AGREES TO BE BOUND BY THESE TERMS. IF YOU DO NOT AGREE TO THE TERMS OF THIS AGREEMENT, THEN YOU DO NOT HAVE PERMISSION TO USE THE ADDITIONAL ARRI CAMERA CONTROL FEATURES ON YOUR small fs-6HD CINE 7 SERIES MONITOR (“THE SOFTWARE”).</li>
                                        <li>Activation. To use the Software, you must activate it with a product key provided by small fs-6HD for the Software. If small fs-6HD determines or suspects that a product key has been used with unauthorized or unlicensed copies of the Software, small fs-6HD reserves the right to suspend the Services or terminate this Agreement (and the right to use the Software) with notice by disabling (or de-activating) the product key or any instance on the physical or virtual instance of the operating system on the small fs-6HD Cine 7 Series Monitor.</li>
                                        <li>License. Subject to the terms and conditions of this Agreement (including satisfaction of any applicable payment obligations), small fs-6HD hereby grants you a non-exclusive, non-transferable, and non-sublicensable license access and use the Software (in object code form only) for its intended purpose in the applicable documentation subject to the limitations set forth in this Agreement and solely in accordance with the manuals and written instructions provided by small fs-6HD. You are also solely responsible for procuring necessary hardware and obtaining licenses to any third party software that may be required to operate in conjunction with the Software, such as an ARRI camera.</li>
                                        <li>Payment. All fees for the Software are payable in the amount and manner set forth in a Purchase Order.</li>
                                        <li>Updates. small fs-6HD may provide updates, upgrades, patches, corrections, modified versions, and supplements for the Software at its sole discretion (“Updates”). small fs-6HD reserves the right to designate specified Updates as requiring purchase of a separate license at any time and without notice to you.</li>
                                        <li>Open Source and Third Party Software. The Software may contain or be distributed with software covered by an open source software license (“Open Source Software”) or covered by a license from a third party (“Third Party Software”). With respect to any Open Source Software that is included in or distributed with the Software, the terms and conditions of this license will not apply to such Open Source Software. For any Third Party Software that is included in or distributed with the Software, the terms and conditions of this license may not apply to such Third Party Software. Some Open Source Software and Third Party Software may contain additional limitations or exclusions of warranties and liabilities. You may find information concerning the inclusion of the Open Source Software and Third Party Software, if any, and the notices, license terms, and disclaimers applicable to such software in the user documentation of the Software. Any breach of the Open Source Software or Third Party Software licenses is also a breach of this Agreement.</li>
                                        <li>License Restrictions. You may not, directly or indirectly:
                                            <ol type="a">
                                                <li>Use (including make any copies of) the Software beyond the scope of the license granted in this Agreement;</li>
                                                <li>Modify, translate, adapt, or otherwise create derivative works or improvements, whether or not patentable, of the Software or any part thereof;</li>
                                                <li>Remove, disable, circumvent, or otherwise create or implement any workaround to any technological copy protection or other security features designed to prevent unauthorized use of the Software;</li>
                                                <li>Combine in an unauthorized manner the Software or any part thereof with, or incorporate the Software or any part thereof in, any other programs;</li>
                                                <li>Reverse engineer, disassemble, decompile, decode or otherwise attempt to derive or gain access to the source code of the Software or any part thereof;</li>
                                                <li>Remove, delete, alter, or obscure any trademarks or any copyright, trademark, patent, or other intellectual property or proprietary rights notices provided on or with the Software, including any copy thereof;</li>
                                                <li>Rent, lease, lend, sell, resell, offer for sale, sublicense, assign, distribute, publish, transfer, or otherwise make available the Software, or any features or functionality of the Software, to any third party for any reason, whether or not over a network or on a hosted basis, including in connection with the internet or any web hosting, wide area network (WAN), virtual private network (VPN), virtualization, time-sharing, service bureau, software as a service, cloud, or other technology or service;</li>
                                                <li>Use the Software in violation of any law, regulation, or rule; or</li>
                                                <li>Use the Software for purposes of competitive analysis of the Software, the development of a competing software product or service or any other purpose that is to small fs-6HD’s commercial disadvantage.</li>
                                            </ol></li>
                                        <li>Technical Support.  Technical support (as further described in the Purchase Order) for the Software may be available generally by email. small fs-6HD may require you to demonstrate that you have a valid license for the Software before providing technical support. small fs-6HD also reserves the right to discontinue technical support for the Software at any time without notice to you. small fs-6HD is not obligated to correct errors in the Software or correct the effects of errors in the Software (e.g., fix your Cine 7 Series monitor or recover lost data).</li>
                                        <li>Collection and Use of Information. small fs-6HD may, directly or indirectly through third party services, collect and store information regarding your navigation or utilization of the Software, Services, and the related instances. small fs-6HD may use such information for any purpose related to any of your use of the Software and the Services, including but not limited to: (A) improving the performance of the Software or developing Updates; and (B) verifying your compliance with the terms of this Agreement and enforcing small fs-6HD’s rights, including all Intellectual Property Rights in and to the Software.</li>
                                        <li>WARRANTY DISCLAIMER. Except for having the right to enter into this agreement and make the grant to the software, THE SOFTWARE AND SERVICES ARE OTHERWISE PROVIDED "AS IS" AND WITH ALL FAULTS AND DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED UNDER THE APPLICABLE LAWS, small fs-6HD, ON ITS OWN BEHALF AND ON BEHALF OF ITS AFFILIATES AND ITS AND THEIR RESPECTIVE LICENSORS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, WITH RESPECT TO THE SOFTWARE AND SERVICES, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OR TRADE PRACTICE. WITHOUT LIMITATION TO THE FOREGOING, small fs-6HD PROVIDES NO WARRANTY OR UNDERTAKING, AND MAKES NO REPRESENTATION OF ANY KIND THAT THE SOFTWARE OR SERVICES WILL MEET YOUR REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE OR WORK WITH ANY OTHER SOFTWARE, APPLICATIONS, SYSTEMS OR SERVICES, OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY STANDARDS OR BE ERROR FREE OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED.</li>
                                        <li>Not Designed for High-Risk Activities. Neither the Software nor Services are fault-tolerant or designed or intended for use in hazardous environments requiring fail-safe performance such as the operation of nuclear facilities, aircraft navigation or communication systems, air traffic control, weapons systems, direct life-support machines, or any other application in which the failure of the Software or Services could lead directly to death, personal injury, or severe physical or property damage. small fs-6HD expressly disclaims any express or implied warranty of fitness for such high-risk activities.</li>
                                        <li>Compliance with Laws. Your use of the Software and Services must strictly comply with all applicable laws and regulations, including laws and regulations relating to privacy and to monitoring the activities of other persons.</li>
                                        <li>Termination. This Agreement will become effective upon your clicking of the acceptance button or downloading or accessing the Software or Services, whichever is earlier, and remain in effect until the earlier of: (a) your failure to comply with any term of this Agreement; (b) your return, destruction, or deletion of all instances and copies of the Software in your possession, and (c) you filing, or having filed against you, a petition for voluntary or involuntary bankruptcy or pursuant to any other insolvency law, making or seeking to make a general assignment for the benefit of its creditors or applying for, or consenting to, the appointment of a trustee, receiver or custodian for a substantial part of your property. Upon termination of this Agreement, you may not use the Software or any Services and must return, destroy and delete all instances and copies of the Software in your possession or from any of your hosts, computers, or storage devices. If requested by small fs-6HD, you will certify in writing to small fs-6HD that you have taken such actions. You are not eligible for any refund or other compensation if this Agreement is terminated for any reason.</li>
                                        <li>Intellectual Property Rights. The Software is licensed under this Agreement. You acquire no ownership interest in and to the Software. Except for the rights expressly granted to you under this Agreement, small fs-6HD reserves and retains the entire right, title, and interest in and to the Software and all intellectual property rights arising out of or relating to the Software. You will safeguard all Software from infringement, misappropriation, theft, misuse, or unauthorized access using nothing less than commercially reasonable efforts. You will promptly notify small fs-6HD if you become aware of any infringement of small fs-6HD’s intellectual property rights and fully cooperate with small fs-6HD in any legal action taken by small fs-6HD to enforce its intellectual property rights.</li>
                                        <li>Feedback. If you provide any feedback to small fs-6HD concerning the functionality and performance of the Software or any Services (including identifying potential errors and improvements) (“Feedback”), you hereby assign to small fs-6HD all right, title, and interest in and to the Feedback, and small fs-6HD is free to use the Feedback in perpetuity without any attribution, payment, or restriction.</li>
                                        <li>Confidentiality. In order to protect the trade secrets and proprietary know-how contained in the Software and or with the Services, you will not decompile, disassemble, or reverse engineer the Software or any Services (including any software or other technology underlying or otherwise used to provide any Services). Without limitation of the foregoing, if you are using a pre-release version of the Software, you will maintain the confidentiality of and not disclose to any third party: (a) all non-public information disclosed by small fs-6HD to you regarding pre-release versions of the Software and (b) all Feedback, Software performance data, and all other information obtained through evaluation of the Software.</li>
                                        <li>LIMITATION OF LIABILITY.
                                            <ol type="a">
                                                <li>EXCEPT TO THE EXTENT THAT IS PROHIBITED BY APPLICABLE LAWS, UNDER NO CIRCUMSTANCES WILL small fs-6HD OR ITS AFFILIATES BE LIABLE FOR ANY CONSEQUENTIAL, SPECIAL, INDIRECT, INCIDENTAL OR PUNITIVE DAMAGES WHATSOEVER (INCLUDING DAMAGES FOR LOSS OF BUSINESS PROFITS, BUSINESS INTERRUPTION, LOSS OF BUSINESS INFORMATION, LOSS OF DATA OR OTHER SUCH PECUNIARY LOSS) ARISING OUT OF THE USE OR INABILITY TO USE THE SOFTWARE OR ANY SERVICES, EVEN IF small fs-6HD OR ITS AFFILIATES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</li>
                                                <li>EXCEPT TO THE EXTENT THAT IS PROHIBITED BY THE APPLICABLE LAWS, IN NO EVENT WILL THE AGGREGATE LIABILITY OF small fs-6HD AND ITS AFFILIATES FOR ANY KIND OF DAMAGES ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT EXCEED THE FEES, IF ANY, PAID BY YOU FOR THE SOFTWARE AND ANY SERVICES. THE FOREGOING EXCLUSIONS AND LIMITATIONS OF LIABILITY AND DAMAGES WILL NOT APPLY TO CONSEQUENTIAL DAMAGES FOR PERSONAL INJURY.</li>
                                                <li>EACH PROVISION OF THESE TERMS THAT PROVIDES FOR A LIMITATION OF LIABILITY, DISCLAIMER OF WARRANTIES, OR EXCLUSION OF DAMAGES IS INTENDED TO AND DOES ALLOCATE THE RISKS BETWEEN THE PARTIES UNDER THESE TERMS. THIS ALLOCATION IS AN ESSENTIAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN THE PARTIES. EACH OF THESE PROVISIONS IS SEVERABLE AND INDEPENDENT OF ALL OTHER PROVISIONS OF THESE TERMS. THE LIMITATIONS IN THIS SECTION WILL APPLY EVEN IF ANY LIMITED REMEDY FAILS OF ITS ESSENTIAL PURPOSE</li>
                                            </ol>
                                        </li>
                                        <li>Government Rights. The Software, including the accompanying documentation, are deemed to be “commercial computer software” and “commercial computer software documentation,” respectively, pursuant to DFAR Section 227.7202 and FAR Section 12.212 under United States laws, as applicable. Any use, modification, reproduction, release, performance, display, or disclosure of the Software and accompanying documentation by the United States Government will be governed solely by the terms of this Agreement and its related Purchase Orders and will be prohibited except to the extent expressly permitted by the terms of this Agreement and its related Purchase Orders.</li>
                                        <li>Indemnification. You will indemnify and hold harmless small fs-6HD and its affiliates (including their respective officers, employees, directors, subsidiaries, representatives, agents, and licensors) and small fs-6HD’s suppliers from and against any damages (including attorney’s fees and expenses), claims, and lawsuits that arise or result from your use of the Software and any Services in breach of this Agreement.</li>
                                        <li>Trademarks. Certain of the product and company names (including, without limitation, small fs-6HD) used in this Agreement or in the Software or any Services may constitute trademarks of small fs-6HD or third parties. You are not authorized to use any such trademarks.</li>
                                        <li>Relationship of the Parties. You acknowledge that this Agreement and the non-exclusive license granted herein does not create any agency or other relationship with small fs-6HD and that any activity that You undertake with the Software or Services is not on behalf of small fs-6HD, its employees, partners or agents, or in furtherance of its business activities.</li>
                                        <li>General
                                            <ol type="a">
                                                <li>This Agreement is governed by the laws of the State of California without regard to conflict of law principles. Except for the right of small fs-6HD to apply to a court of competent jurisdiction for a temporary restraining order, a preliminary injunction, or other equitable relief to preserve the status quo or prevent irreparable harm, any dispute as to the interpretation, enforcement, breach, or termination of this Agreement will be settled by binding arbitration in Orange County, California under the Rules of the American Arbitration Association by one arbitrator appointed in accordance with the Rules. Judgment upon the award rendered by the arbitrators may be entered in any court of competent jurisdiction. Except as set forth above, you and small fs-6HD submit to the personal and exclusive jurisdiction of the state courts and federal courts located within Orange County, California for resolution of any lawsuit or court proceeding arising out of or related to this Agreement or the licenses granted hereunder.</li>
                                                <li>small fs-6HD will not be responsible or liable to you, or deemed in default or breach hereunder by reason of any failure or delay in the performance of its obligations hereunder where such failure or delay is due to strikes, labor disputes, civil disturbances, riot, rebellion, invasion, epidemic, hostilities, war, terrorist attack, embargo, natural disaster, acts of God, flood, fire, sabotage, fluctuations or non-availability of electrical power, heat, light, air conditioning or your equipment, loss and destruction of property or any other circumstances or causes beyond small fs-6HD’s reasonable control.</li>
                                                <li>You may not assign or otherwise transfer any of your rights, or delegate or otherwise transfer any of your obligations or performance, under this Agreement, by operation of law or otherwise, without small fs-6HD’s prior written consent. Any purported assignment, delegation or transfer in violation of this Section is void. small fs-6HD may freely assign or otherwise transfer all or any of its rights, or delegate or otherwise transfer all or any of its obligations or performance, under this Agreement without your consent. This Agreement is binding upon and inures to the benefit of the parties hereto and their respective permitted successors and assigns.</li>
                                                <li>This Agreement, together with the Purchase Order, constitutes the sole and entire agreement between you and small fs-6HD with respect to the subject matter contained herein, and supersedes all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to such subject matter.</li>
                                                <li>If there is a discrepancy between this Agreement and the Purchase Order, this Agreement will control.</li>
                                                <li>This Agreement may only be amended, modified or supplemented by an agreement in writing signed by each party hereto. No waiver by any party of any of the provisions hereof will be effective unless explicitly set forth in writing and signed by the party so waiving. Except as otherwise set forth in this Agreement, no failure to exercise, or delay in exercising, any right, remedy, power or privilege arising from this Agreement will operate or be construed as a waiver thereof; nor will any single or partial exercise of any right, remedy, power or privilege hereunder preclude any other or further exercise thereof or the exercise of any other right, remedy, power or privilege.</li>
                                                <li>If any term or provision of this Agreement is invalid, illegal or unenforceable in any jurisdiction, such invalidity, illegality or unenforceability will not affect any other term or provision of this Agreement or invalidate or render unenforceable such term or provision in any other jurisdiction.</li>
                                                <li>If you have any questions about this Agreement or if you would like to contact small fs-6HD for any other reason, please contact small fs-6HD at support@small fs-6hd.com.</li>
                                            </ol>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`modal-backdrop fade ` + modalClass} style={modalStyles}></div>
                </section>

                <Footer />

            </div>
        );
    }



}